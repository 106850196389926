export default {
  init() {
    // JavaScript to be fired on all pages

    var goTop = function () {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 800) {
          $('.go-top-v1').addClass('show');
        } else {
          $('.go-top-v1').removeClass('show');
        }
      });
  
      $('.go-top-v1').on('click', function () {
        $('html, body').animate({ scrollTop: 0 }, 1000);
        return false;
      });
    };
    
    var tabs = function () {
      $('.flat-tabs').each(function () {
        $(this).children('.content-tab').children().hide();
        $(this).children('.content-tab').children().first().show();

        $(this)
        .find('.menu-tab')
        .children('li')
        .on('click', function (e) {
          var liActive = $(this).index(),
            contentActive = $(this)
              .siblings()
              .removeClass('active')
              .parents('.flat-tabs')
              .children('.content-tab')
              .children()
              .eq(liActive);

          contentActive.addClass('active').fadeIn('slow');
          contentActive.siblings().removeClass('active');
          $(this)
            .addClass('active')
            .parents('.flat-tabs')
            .children('.content-tab')
            .children()
            .eq(liActive)
            .siblings()
            .hide();
          e.preventDefault();
        });
      });
    };
    $(window).on('load', function () {
      goTop();
      tabs();
    });
  },
  finalize() {
    /**
   * MOBILE HAMBURGER ANIMATION, HEADER BG WHEN SCROLLING
   */
  function initializeMobileMenu() {
    $('.mobile-hamburger').click(function() {
        $(this).toggleClass('is-active');
        $('.banner').toggleClass('is-active');
        $('.mobile-wrapper').toggleClass('is-active');
    });

    $('.navigation__link').click(function() {
      console.log('working');
      $('.mobile-hamburger').removeClass('is-active');
      $('.banner').removeClass('is-active');
      $('.mobile-wrapper').removeClass('is-active');
  });
  }
  
    // JavaScript to be fired on all pages, after page specific JS is fired
    initializeMobileMenu();
  
  },
};
